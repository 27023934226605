import { Component, Prop, Vue } from "vue-property-decorator";
import { ImportResult } from "../ImportWizard.component";
import Template from "./ResultView.template.vue";

@Component({
  mixins: [Template]
})
export default class ResultView extends Vue {
  @Prop({ required: true })
  public result!: ImportResult;
  @Prop({ required: true })
  public success!: boolean;
  protected expandedItem: number[] = [];

  get message() {
    if (this.result) {
      const message = { status: "", successful: "", failed: "", text: "" };
      if (this.result.status === "done") {
        message.status = `${this.$t("importModule.import_completed")}`;
      } else {
        message.status = `${this.$t("importModule.import_failed")}`;
        if (
          this.result.status === "aborted" ||
          this.result.status === "invalid_header"
        ) {
          message.text = this.result.text;
        }
      }
      return message;
    }
    return "";
  }
}
